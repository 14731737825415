<template>
    <div class="Cartoondetail">
        <div class="title">
            表情包详情
        </div> 

        <div class="nav">
        <span style="font-size: 14px;cursor: pointer;" @click="back">返回</span>
        <span style="padding: 0 20px;">/</span>
        <span style="font-family: 700;color: #515a6e;">            表情包详情
        </span>
    </div>
        <div v-html="detail.content"></div>
        
    </div>
</template>

<script>
export default{
    data(){
        return{
            id:'001',
            detail:null
        }
    },
    mounted(){
        this.detail = JSON.parse( this.$route.query.data);
        
    },
    methods:{
        back(){
            this.$router.push({ name: "expression" });
        }
    }
}
</script>

<style scoped>
.Cartoondetail{
    width: 1200px;
    padding-bottom: 50px;
    box-shadow: 0 3px 8px rgba(0,0,0,.3);
    margin: 0 auto;
    padding: 50px 25px 100px;
    box-sizing: border-box;
    border-radius: 15px;
    background-color: #fff;
}
.title{
    background: url('../assets/image/border.png') no-repeat center center;
    background-size: 100% 100%;
    width: 300px;
    height: 109px;
    line-height: 109px;
    font-size: 32px;
    font-family: 700;
    margin: 0 auto;
}
.imgs{
    width: 800px;
}
.nav{
    text-align: left;
    padding: 20px 0;
}
</style>