<template>
  <el-card class="cartoon">
    <div class="title">音乐中心</div>

    <div class="nav">
      <el-link :underline="false" href="/">首页</el-link>
      <span style="padding: 0 20px">/</span>
      <span style="font-family: 700; color: #515a6e">音乐中心</span>
    </div>

    <div class="box">
      <div
        class="item"
        @click="paly(item)"
        v-for="(item, idx) in list"
        :key="item.name"
      >
        <img class="imgs" :src="$base.imgUrl+item.image" alt="" />
        <div class="bg-icon">
          <div class="bg-fix"></div>
        </div>
        <img class="imgs-paly" src="@/assets/image/btn-play.png" alt="" />
        <span v-text="item.title" class="item-title"></span>
      </div>
      <div v-for="v in 4" :key="v" style="width: 270px; height: 0"></div>
    </div>
    <aplayer
      ref="aplayer"
      class="flex-audio"
      v-if="deMusic.title"
      autoplay
      :music="{
        title: deMusic.title,
        src: $base.imgUrl+deMusic.filename,
        pic: $base.imgUrl+deMusic.image,
      }"
    >
    </aplayer>
  </el-card>
</template>
  
  <script>
import aplayer from "vue-aplayer";

export default {
  components: {
    aplayer,
  },
  data() {
    return {
      list: [
       
      ],
      
      deMusic: {},
    };
  },
  created(){
    this.$api.index.getData({sty:2}).then(res=>{
            this.list = res.result
        })
  },
  methods:{
    paly(item){
      this.deMusic = item
      this.$nextTick(()=>{
        this.$refs.aplayer.play();

      })
    },
  },
};
</script>
  
  <style scoped>
@keyframes fx {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.cartoon {
  width: 1200px;
  padding-bottom: 50px;
  margin: 0 auto;
  padding: 50px 25px 50px;
  box-sizing: border-box;
  border-radius: 15px;
}
.title {
  background: url("../assets/image/border.png") no-repeat center center;
  background-size: 100% 100%;
  width: 300px;
  height: 109px;
  line-height: 109px;
  font-size: 32px;
  font-family: 700;
  margin: 0 auto;
}
.nav {
  text-align: left;
  padding: 20px 0;
}
.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.item {
  box-sizing: border-box;
  padding: 20px 18px 5px 20px;
  transition: all 0.2s linear;
  border-radius: 2px;
  width: 270px;
  cursor: pointer;
  position: relative;
}
.item:hover {
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.15);
  background: #fff;
}
.item:hover .bg-fix {
  height: 60px;
  width: 60px;
  background: rgb(235, 47, 94);
  animation: fx 0.5s both;
  border-radius: 50%;
}
.imgs {
  width: 230px;
  height: 150px;
  overflow: hidden;
  border-radius: 2px;
  background: #666;
  display: block;
  object-fit: cover;
}
.item-title {
  text-align: center;
  font-size: 14px;
  line-height: 30px;
}
.imgs-paly {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.bg-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.flex-audio {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: 350px;
}
</style>