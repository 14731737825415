export const dizhi='江西省赣州市章贡区兴国路18号财智广场（赣州书城）A栋商业A2005#'
export const gongsi='江西开创数码科技有限公司'
export const gongsi2='Copyright © 2017 江西开创数码科技有限公司'
export const dianhua='0797-5557588'

export const mixins = {
    data() {
      return {
        title:'开创数码',
        gongsi:gongsi,
        gongsi:gongsi2,
        dianhua: dianhua,
        dizhi: dizhi,
        youxiang:'1318827509@qq.com',
        qq:'1318827509',
        // 首页底部
        beianhao1: "赣公网安备 36070202000237号",
        beianhao2: "| 赣ICP备15011253号-6",
        beianhao3: "| 增值电信业务经营许可证 赣 B2-20160055 | 赣网文（2022）3851-056号",

      };
    },
  };