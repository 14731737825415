<template>
  <div class="loginindex" style="height: 100vh">
    <div class="bg-flur"></div>
    <div class="boxwrap flex-jc">
      <div class="content flex-jc-sb">
        <div class="contentbody-L flex margin-top-xl">
          <img
            class="logo"
            style="width: 300px; height: 300px"
            src="@/assets/logo.png"
            alt=""
          />
          <div>
            <!-- <div class="intro padding">您好</div>
            <div class="intro padding">欢迎来到须弥幻境</div> -->
          </div>
        </div>
        <div class="contentbody-R">
          <div class="flex-jc-ac padding-bottom-sm">
            <h3 style="color: #000">您好，欢迎来到！</h3>
          </div>
          <el-tabs style="" type="card" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="登录" name="first">
              <div>
                <div class="flex-jc">
                  <div class="box1 flex-jc">
                    <input
                      class="phone"
                      v-model="username"
                      maxlength="11"
                      placeholder="请输入用户名"
                    />
                  </div>
                </div>
                <div class="padding-bottom flex-jc" style="position: relative">
                  <div class="box2 flex-jc">
                    <input
                      class="veri"
                      v-model="password"
                      maxlength="18"
                      type="password"
                      placeholder="请输入密码"
                    />
                  </div>
                </div>
              </div>
              <div class="flex-jc">
                <div class="uni-btn pointer" @click="login">登录</div>
              </div></el-tab-pane
            >
            <el-tab-pane label="注册" name="second">
              <div>
                <div class="flex-jc">
                  <div class="box1 flex-jc">
                    <input
                      class="phone"
                      v-model="usernameR"
                      maxlength="11"
                      placeholder="请输入用户名"
                    />
                  </div>
                </div>
                <div class="flex-jc" style="position: relative">
                  <div class="box2 flex-jc">
                    <input
                      class="veri"
                      v-model="passwordR"
                      maxlength="18"
                      type="password"
                      placeholder="请输入密码"
                    />
                  </div>
                </div>
                <div class="padding-bottom-xs flex-jc" style="position: relative">
                  <div class="box2 flex-jc">
                    <input
                      class="veri"
                      v-model="password_confirmation"
                      maxlength="18"
                      type="password"
                      placeholder="请再次输入密码"
                    />
                  </div>
                </div>
              </div>
              <div class="flex-jc">
                <div class="uni-btn pointer" @click="register">注册</div>
              </div></el-tab-pane
            >
          </el-tabs>
          <div class="flex-jc flex-col"></div>

          <!-- <div class="gxpic flex-jc-ac">
            <span>登录即代表你同意</span>
            <span
              class="pointer"
              style="color: rgb(32, 130, 211)"
              @click="$router.push('/login/yhxy')"
              >《用户协议》</span
            >
            和
            <span
              class="pointer"
              style="color: rgb(32, 130, 211)"
              @click="$router.push('/login/yszc')"
              >《隐私政策》</span
            >
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setStorage, getStorage } from "@/common/utils.js";
export default {
  name: "HelloWorld",
  created() {
    let token = getStorage("userinfo");
    if (token) this.$router.push("/");
  },
  data() {
    return {
      activeName: "first",
      password: null,
      passwordR: null,
      username: null,
      usernameR: null,
      password_confirmation: null,
      lock: false,
    };
  },
  watch: {
    // $route() {
    //   window.location.reload(); //监测到路由发生跳转时刷新一次页面
    // },
  },
  methods: {
      handleClick(tab, event) {
          console.log(tab, event);
     },
    // 登录
    login() {
     
      this.$api.login
        .login({
          password: this.password,
          username: this.username,
        })
        .then((res) => {
          this.$message({
          message: '登录成功',
          type: 'success'
        });
          setStorage("userinfo", res.result);
            this.$router.push({name:'Home'})
        })
        
    },
    // 注册
    register() {
    
      this.$api.login
        .register({
          password: this.passwordR,
          username: this.usernameR,
          password_confirmation:this.password_confirmation,
        })
        .then((res) => {
            this.$message({
            message: '登录成功',
            type: 'success'
            });
            setStorage("userinfo", res.result);
            this.$router.push({name:'Home'})
        })
    },
    // send() {
    //   this.$api.login
    //     .send({
    //       mobile: this.mobile,
    //       event: this.event,
    //     })
    //     .then((res) => {
    //       if (res.code == 1) {
    //         console.log(res);
    //       }
    //     });
    // },
  },
};
</script>

<style scoped >
.boxwrap {
  width: 100%;
}
/* .bg-flur {
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-image: url(../assets/logo.png);
  opacity: 0.7;
  filter: blur(8px);
  z-index: 1;
  -webkit-mask: linear-gradient(#fff, transparent);
} */
.content {
  width: 1080px;
  position: relative;
  margin-top: 150px;
  z-index: 2;
}
.contentbody-R {
  background-color: #fff;
  padding-top: 50px;
  height: 400px;
  width: 400px;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
}
.contentheader {
  width: 100%;
  padding: 100px 0 24px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  font-size: 26px;
  z-index: 2;
}
.box1 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  margin-bottom: 16px;
  width: 304px;
}
.phone {
  color: #000;
  border: none;
  outline: none;
  display: block;
  line-height: 40px;
  flex: 1;
  border-radius: 30px;
  background: rgba(192, 187, 187, 0.5);
  padding: 0 16px;
}
input::-webkit-input-placeholder {
  color: rgb(135, 134, 134);
  font-size: 12px;
}
.box2 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 304px;
}
.veri {
  color: #000;
  border: none;
  outline: none;
  display: block;
  line-height: 40px;
  flex: 1;
  border-radius: 30px;
  background: rgba(192, 187, 187, 0.5);
  padding: 0 16px;
}
.code {
  color: #fefefe;
  position: absolute;
  left: 260px;
}
.gxpic {
  color: #1a1a1a;
  font-weight: 500;
  font-size: 12px;
  margin-top: 41px;
}
.uni-btn {
  font-size: 14px;
  color: #fff;
  background: linear-gradient(90deg, #1a1a1a, #1a1a1a);
  padding: 0 33px;
  width: 290px;
  border-radius: 20px;
  line-height: 40px;
}
 .el-tabs__header is-top {
  padding-left: 50px;
}
</style>
