<template>
<div class="cartoon">
    <div class="title">
        漫画中心
    </div> 

    <div class="nav">
        <span style="font-size: 14px;cursor: pointer;" @click="toHome">首页</span>
        <span style="padding: 0 20px;">/</span>
        <span style="font-family: 700;color: #515a6e;">漫画中心</span>
    </div>

    <div class="box">
        <div
        class="item"
        v-for="item in list"
        :key="item.title"
        @click="toDetail(item)"
        >
            <img class="imgs" :src="$base.imgUrl+item.image" alt="">
            <span v-text="item.title" class="item-title"></span>
        </div>

        <div v-for="i in 4" :key="i" style="width: 250px;height: 0;"></div>
    </div>
</div>
</template>

<script>
export default{
    data(){
        return{
            list:[
                
            ]
        }
    },
    created(){
        this.$api.index.getData({sty:1}).then(res=>{
            this.list = res.result
        })
    },
    methods:{
        toDetail(item){
            this.$router.push({ name: "Cartoondetail", query: { data:JSON.stringify(item) } });
        },
        toHome(){
            this.$router.push({path:'/'})
        }
    }
}
</script>

<style scoped>
.cartoon{
    width: 1200px;
    padding-bottom: 50px;
    box-shadow: 0 3px 8px rgba(0,0,0,.3);
    margin: 0 auto;
    padding: 50px 25px 100px;
    box-sizing: border-box;
    border-radius: 15px;
    background-color: #fff;
}
.title{
    background: url('../assets/image/border.png') no-repeat center center;
    background-size: 100% 100%;
    width: 300px;
    height: 109px;
    line-height: 109px;
    font-size: 32px;
    font-family: 700;
    margin: 0 auto;
}
.nav{
    text-align: left;
    padding: 20px 0;
}
.box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.item{
box-sizing: border-box;
  padding: 20px 18px 5px 20px;
  transition: all .2s linear;
  border-radius: 2px;
  width: 287px;
  float: left; 
  cursor: pointer;
}
.item:hover{
    box-shadow: 0 0 7px 0 rgba(0,0,0,.15);
    background: #fff;
}
.imgs{
    width: 250px;
height: 150px;
overflow: hidden;
border-radius: 2px;
background: #666;
display: block;
}
.item-title{
    text-align: center;
font-size: 14px;
line-height: 30px;
}
</style>