<template>
    <div class="cartoon">
        <div class="title" style="margin-bottom:20px;">
            自审制度
        </div> 
    
        <div class="nav" v-html="detail">
            
        </div>
    
        
    </div>
    </template>
    
    <script>
    export default{
        data(){
            return{
                detail:`
                <p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">为加强网络文化内容建设与管理，规范网络文化经营单位产品及服务内容自审工作，增强本公司自主管理能力和自律责任，确保网络文化健康，快速发展，根据《互联网文化管理暂行规定》，结合本单位网展需要，特制度本制度络文化建设与管理的实际情况河发。</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">一．网络文化经营内容自审管理内容，工作流 程 及责任追究内容自审机构的组成 根据文化部制定的《网络文化经营单位内容自审管理办法》单位挑选了由3名政治敏感性较强思想上有独特见解的员工 组成自审部门。自审部门的审核人员工作要严谨，只有经过文化部的培训，取得《内容审核人员证书》的人员才能真正担任到公司的自审人员。 自审部门的总负责人由 担任，主要负责自审工作的的最后审核，包括对网络文化产品及服务服务的内容合法性的准确判断及运行等。 自审部门的自审人员，等网络文化产品及服务的内容的自审人员要对其审核内容认真负责。 自审人员的职责 掌握内容审核的政策法规和相关知识独立表达审核意见参加文化行政部门组织的业务培训 以最终保证本企业的产品和服务的合法性和合规性为目的，对在自身工作中发现的违法违规的产品及服务内容进行记录并提请终止，作出提交修改，督促修改，复审等审核意见，重大问题向省文化厅报告； .负责保管审查记录 .对产品及服务内容的合法合规性不能准确判断的，可向省文化厅申请行政指导； .每年应至少参加1次由省文化厅组织的业务培训； 组织内部培训工作，对企业开发、运营人员进行内容审查方面的培训。</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">二、审查流程 对企业自主研发的产品，在研发阶段进行培训，对故事背景、美术素材等进行初步的筛查； 自审人员在产品公测之前，依据内容审查的相关规定，对产品及其服务内容（包括宣传推广和活动策划）进行审查，对违法违规的内容进行记录，并签发初审意见； 对初审有问题的产品，退回研发企业或部门进行修改，并对修改后的内容进行复查； 对内容的合法合规性不能准确判断的，应向省文化厅申请行政指导； 对复查仍有问题的，应按照上述第2-4项规定的内容重新进行审核； 在产品公测前，对产品客户端、公司官网、产品官网进行审查，审查内容包括证照使用、实名注册、实名信息补填、用户协议必备条款、适龄提示、家长监护工程等内容，合格后方可公测； 对审查完成的产品，自审人员提出同意进行公测的意见并签字确认，上交内容审查管理工作的主要负责人； 内容审查管理工作的主要负责人签字确认后，根据规定要求，向文化行政管理部门提交产品备案； 日常对产品和服务内容进行监督检查，包括产品版本更新后的内容、产品客户端、公司官网、产品官网、宣传推广和活动策划等各个方面和环节，发现问题提交检查意见，报本企业内容审查管理工作主要负责人； 自审人员的所有审查意见应归档留存，保存时间不少于两年； 自审工作流程图。</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">三、审查标准 表演中不得含有以下内容： 表演中涉黄，涉政，谈论宗教，违法，侵权等敏感话题； 表演内容不得造成强烈的感官、精神刺激，及可致人身心不适的动作、语言、画面和音效。 表演者不得发布包含色情内容的黄色网链接、淫秽视频内容的 。 机构设置 审核部门由运营管理部和总经理组成。运营管理部人员负责对视频及其服务内容（包括宣传推广和活动策划）进行审查并签字，对违法违规的内容进行记录，并签发初审意见。然后报运营管理部经理复审，复审人员对网站内容进行再次审核，并签字发表复审意见，总经理 负责最终审核公司拟上线的视频并签字。</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="margin: 0px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; vertical-align: baseline; background: 0px 0px; font-weight: bolder; font-size: 16px;">通知程序</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">依照《网络文化经营单位内容自审管理办法》的有关要求制定本制度；设立专门岗位及专人承担产品内容自审工作；自审由运营管理部人员实施审核，审核人员填写审核意见并签字后，报本单位内容审查管理工作的主要负责人 签字。其中主要负责人为公司主要领导，该领导为内容审查管理工作的主要负责人。</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="margin: 0px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; vertical-align: baseline; background: 0px 0px; font-weight: bolder; font-size: 16px;">（一）审查人员职责</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">①本公司审核人员掌握内容审核的政策法规和相关知识；</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">②审核人员均可在内容自审这一工作中表达其独立的审核意见；对初审有问题的内容，退回网站编辑人员进行修改，并对修改后的内容进行复查；</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">③ 审核人员须以最终保证本企业的产品和服务的合法性和合规性为目的，对在自审工作中发现违法违规的产品及服务内容进行记录并提请中止，作出提交修改，督促修改，复审等审核意见。重大问题向省文化厅报告；</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">④审核人员负责保管审查记录；</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">⑤审核人员遇有对产品及服务内容的合法合规性不能准确判断的，可向省文化厅申请行政指导；</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">⑥ 审核人员每年至少应当参加1次业务培训；</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">⑦ 审核人员有组织内部培训工作，对企业开发、运营人员进行内容审查方面的培训的责任。</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">权利人应对通知书的真实性负责。若通知书的内容不真实，权利人将承担由此造成的全部法律责任。</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">赶马健康收到权利人的通知及完整的证明材料后，将立即删除涉嫌侵权的作品，或者断开涉嫌侵权作品的链接。</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="margin: 0px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; vertical-align: baseline; background: 0px 0px; font-weight: bolder; font-size: 16px;">（二）审核流程</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">表演信息收集小组负责前期根据有关规定收集、整理、制作要上线的表演产品；表演信息审核小组将拟上线的表演信息报部门领导审核、签字，经领导审核同意、签字后方可上线发布；所有表演经审批结束后，保证符合各类法律法规之后，方可提交给技术部门，进行产品的网上上线操作。 鉴别方式分为自动鉴别和人工鉴别，鉴别内容包括：图片、文字、视频等方面。 自动鉴别： 安装屏蔽词库，通过接入第三方专业技术服务公司接口的形式，来实现对服务端图片文件、屏蔽关键词库的自动鉴别工作。发现违规图片后，系统会记录相关信息，然后删除违规图片。</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="margin: 0px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; vertical-align: baseline; background: 0px 0px; font-weight: bolder; font-size: 16px;">人工鉴别：</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">在运营管理后台中，用户头像、封面照等图片文件皆可查询预览，并对违规的图片进行管理操作。涉黄、涉恐、有低俗内容。 如若通过严格的自审制度（自动+人工）之后，网站上仍然存留涉黄、涉恐、涉政、暴力、低俗文字、图片、视频等内容，突发事案处理流程如下：</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">①技术上掐断处理、保留证据和用户信息，产品立刻下线并进行调整、修改，复审合格后，方可重新上线</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">②对事态分析。调查、核实内部及外部责任及分析，重新解决方案，并记录在案，相关记录保存至少两年。</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">③逐级上报上级领导（运营管理部主管-总经理 ）</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">④报案处理。含有严重宣扬淫秽、暴力、教唆犯罪或者危害社会公德的内容，违反《互联网文化管理暂行规定》的产品，上报法务部，由法务部报网监局/公安局进行处理</span>
</p>
<p style="margin-top: 0px; margin-bottom: 10px; padding: 0px; transition: all 0.5s ease 0s; border: 0px; outline: 0px; font-size: 14px; vertical-align: baseline; background: 0px 0px rgb(255, 255, 255); font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 1.6; font-family: &quot;Microsoft yahei&quot;, simsun, tohoma; text-indent: 2em; color: rgb(41, 44, 45); white-space: normal; text-align: left;">
    <span style="font-size: 16px;">⑤策划部：从部门领导开始，严格学习《互联网文化管理暂行规定》、《网络文化经营单位内容自审管理办法》和《网络表演经营活动管理办法》等规章制度，信息收集小组在前期搜集视频过程中及签署版权合作协议，严格按照公司制定的审核制度进行，从源头上阻止违规产品流通、上线、传播。</span>
</p>
<p>
    <br/>
</p>
                `
            }
        },
       
    }
    </script>
    
    <style scoped>
    .cartoon{
        width: 1200px;
        padding-bottom: 50px;
        /* box-shadow: 0 3px 8px rgba(0,0,0,.3); */
        margin: 0 auto;
        padding: 50px 25px 100px;
        box-sizing: border-box;
        border-radius: 15px;
        background-color: #fff;
    }
    .title{
        background: url('../assets/image/border.png') no-repeat center center;
        background-size: 100% 100%;
        width: 300px;
        height: 109px;
        line-height: 109px;
        font-size: 32px;
        font-family: 700;
        margin: 0 auto;
    }
    
    </style>