<template>
<div class="cartoon">
    <div class="title">
        表情中心
    </div> 

    <div class="nav">
        <span style="font-size: 14px;cursor: pointer;" @click="toHome">首页</span>
        <span style="padding: 0 20px;">/</span>
        <span style="font-family: 700;color: #515a6e;">表情中心</span>
    </div>

    <div class="box">
        <div
        class="item"
        v-for="item in list"
        :key="item.title"
        @click="toDetail(item)"
        >
            <div class="item-box">
                <img class="imgs" :src="$base.imgUrl+item.image" alt="">
                <el-button v-if="isPay==0" type="primary" @click="pay">￥付费下载 : 1元</el-button>
            </div>
            <span v-text="item.title" class="item-title"></span>

        </div>

        <div v-for="i in 4" :key="i" style="width: 300px;height: 0;"></div>
    </div>

    <el-dialog
  title="支付界面"
  :visible.sync="dialogVisible"
  append-to-body 
  >
  <div class="top">
    <i class="el-icon-warning"></i>
    <div class="top-right">
        <p>请先添加管理员 微信或 支付宝为好友，然后完成转账。</p>
        <p>付款完成后，将校验码 <span>181924</span> 发送给管理员，我们会尽快为您开通钻石会员，并以邮件和站内信通知您。</p>
    </div>
  </div>
  <div style="text-align: center;">
    <img class="pay" style="width:300px;" src="@/assets/image/pay.jpg" alt="">

  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
</el-dialog>
</div>
</template>

<script>
import {getStorage} from "@/common/utils.js";

export default{
    data(){
        return{
            dialogVisible:false,
            isPay:0,
            list:[

            ]
        }
    },
    created(){
        if(getStorage('userinfo')&&getStorage('userinfo').is_show_emoji==1){
            this.isPay = 1
        }
        this.$api.index.getData({sty:3}).then(res=>{
            this.list = res.result
        })
    },
    methods:{
        toDetail(item){
            if(this.isPay==0){
                return
            }
            this.$router.push({ name: "expressionDetail", query: {data:JSON.stringify(item)} });
        },
        pay(){
            this.dialogVisible=true
        },
        toHome(){
            this.$router.push({path:'/'})
        }
    }
}
</script>

<style scoped>
/* zhifu */
.top{
    display: flex;
    justify-content: space-around;
    background: #F0FAFF;
    border: 1px solid #abdcff;
    align-items: center;
    border-radius: 2px;
    padding: 20px;
}
.el-icon-warning{
    color: #abdcff;
    font-size: 26px;
}
.top-right{
    margin-left: 20px;
    width:80%;
}
.top-right span{
    font-size: 16px;
    color:red;
    font-weight: bold;
}
.pay{
    margin-top:20px;
    width: 100%;
}
/*  */

.cartoon{
    width: 1200px;
    padding-bottom: 50px;
    box-shadow: 0 3px 8px rgba(0,0,0,.3);
    margin: 0 auto;
    padding: 50px 25px 100px;
    box-sizing: border-box;
    border-radius: 15px;
    background-color: #fff;
}
.title{
    background: url('../assets/image/border.png') no-repeat center center;
    background-size: 100% 100%;
    width: 300px;
    height: 109px;
    line-height: 109px;
    font-size: 32px;
    font-family: 700;
    margin: 0 auto;
}
.nav{
    text-align: left;
    padding: 20px 0;
}
.box{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}
.item{
    position:relative;
box-sizing: border-box;
  transition: all .2s linear;
  border-radius: 2px;
  float: left; 
  cursor: pointer;
  width: 300px;
  height: 240px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-box{
  width: 250px;
  height: 180px;
  background-color: #666666;
}
.item:hover{
    box-shadow: 0 0 7px 0 rgba(0,0,0,.15);
    /* background: #fff; */
}

.imgs{
height: 130px;
overflow: hidden;
border-radius: 2px;
background: #666;
object-fit: cover;
display: block;
margin: 0 auto;
}
.item-title{
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 210px;
    text-align: center;
    font-size: 14px;
    line-height: 30px;
}
</style>