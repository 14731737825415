<template>
    <div class="cartoon">
        <div class="title" style="margin-bottom:20px;">
            用户纠纷处理
        </div> 
    
        <div class="nav" v-html="detail">
            
        </div>
    
        
    </div>
    </template>
    
    <script>
import { dizhi,gongsi,dianhua } from '../mixin/index.js';
    export default{
        data(){
            return{
                detail:`<p style="margin: 0px 0px 10px; text-indent: 28px; padding: 0px; vertical-align: baseline; text-align: left;">
    <span style="color: rgb(41, 44, 45); letter-spacing: 0px; background: rgb(255, 255, 255); font-size: 16px; font-family: 微软雅黑;">${gongsi}（下称“${gongsi}”）经营${gongsi}网站及其相关软件、客户端。前述平台中包含众多用户上传的信息、第三方发布的信息、频道管理者发布的信息、以及其他来自互联网的信息。${gongsi}为保护著作权人的合法权利，根据我国相关法律法规的规定，将通过以下途径保护${gongsi}经营的网站内作品的著作权：</span>
</p>
<p style="margin: 0px 0px 10px; text-indent: 28px; padding: 0px; vertical-align: baseline; text-align: left;">
    <span style="font-family: 微软雅黑; color: rgb(41, 44, 45); letter-spacing: 0px; background: rgb(255, 255, 255); font-size: 16px;">“作品”是指受《中华人民共和国著作权法》保护的文学、艺术和科学作品，包括但不限于文字作品、美术作品、表演、录音录像制品等。</span>
</p>
<p style="margin: 0px 0px 10px; text-indent: 28px; padding: 0px; vertical-align: baseline; text-align: left;">
    <span style="font-family: 微软雅黑; color: rgb(41, 44, 45); letter-spacing: 0px; background: rgb(255, 255, 255); font-size: 16px;">“权利人”是指作品的著作权人或者经著作权人合法授权而享有作品的信息网络传播权的权利享有人。</span>
</p>
<p style="margin: 0px 0px 10px; text-indent: 28px; padding: 0px; vertical-align: baseline; text-align: left;">
    <span style="font-size: 16px;"><strong><span style="color: rgb(41, 44, 45); letter-spacing: 0px; background: rgb(255, 255, 255); font-size: 16px; font-family: 微软雅黑;">通知程序</span></strong></span>
</p>
<p style="margin: 0px 0px 10px; text-indent: 28px; padding: 0px; vertical-align: baseline; text-align: left;">
    <span style="color: rgb(41, 44, 45); letter-spacing: 0px; background: rgb(255, 255, 255); font-size: 16px; font-family: 微软雅黑;">权利人认为${gongsi}经营的网站中相关信息、搜索或链接的内容所涉及的作品侵犯了其信息网络传播权或者被删除、改变了自己的权利管理电子信息的，可向${gongsi}提交书面通知，要求删除该作品，或者断开该作品的链接。通知书需权利人亲笔签名，若为单位则需加盖单位公章。</span>
</p>
<p style="margin: 0px 0px 10px; text-indent: 28px; padding: 0px; vertical-align: baseline; text-align: left;">
    <span style="font-size: 16px;"><strong><span style="color: rgb(41, 44, 45); letter-spacing: 0px; background: rgb(255, 255, 255); font-size: 16px; font-family: 微软雅黑;">通知书应当包含下列内容：</span></strong></span>
</p>
<p style="margin: 0px 0px 10px; text-indent: 28px; padding: 0px; vertical-align: baseline; text-align: left;">
    <span style="font-family: 微软雅黑; color: rgb(41, 44, 45); letter-spacing: 0px; background: rgb(255, 255, 255); font-size: 16px;">（一） 权利人的姓名（名称）、联系方式、地址、身份证复印件（自然人）、单位登记证明复印件（单位）；</span>
</p>
<p style="margin: 0px 0px 10px; text-indent: 28px; padding: 0px; vertical-align: baseline; text-align: left;">
    <span style="font-family: 微软雅黑; color: rgb(41, 44, 45); letter-spacing: 0px; background: rgb(255, 255, 255); font-size: 16px;">（二） 要求删除或者断开链接的侵权作品的准确名称和网络地址；</span>
</p>
<p style="margin: 0px 0px 10px; text-indent: 28px; padding: 0px; vertical-align: baseline; text-align: left;">
    <span style="font-family: 微软雅黑; color: rgb(41, 44, 45); letter-spacing: 0px; background: rgb(255, 255, 255); font-size: 16px;">（三） 认为构成侵权的初步证明材料，包括但不限于对作品享有著作权或依法享有信息网络传播权的权属证明、创作手稿、经权威机构签发的作品创作时间戳、作品备案证书、列举证据证明侵权事实。</span>
</p>
<p style="margin: 0px 0px 10px; text-indent: 28px; padding: 0px; vertical-align: baseline; text-align: left;">
    <span style="font-family: 微软雅黑; color: rgb(41, 44, 45); letter-spacing: 0px; background: rgb(255, 255, 255); font-size: 16px;">权利人应对通知书的真实性负责。若通知书的内容不真实，权利人将承担由此造成的全部法律责任。</span>
</p>
<p style="margin: 0px 0px 10px; text-indent: 28px; padding: 0px; vertical-align: baseline; text-align: left;">
    <span style="color: rgb(41, 44, 45); letter-spacing: 0px; background: rgb(255, 255, 255); font-size: 16px; font-family: 微软雅黑;">${gongsi}收到权利人的通知及完整的证明材料后，将立即删除涉嫌侵权的作品，或者断开涉嫌侵权作品的链接。</span>
</p>
<p style="margin: 0px 0px 10px; text-indent: 28px; padding: 0px; vertical-align: baseline; text-align: left;">
    <span style="font-size: 16px;"><strong><span style="color: rgb(41, 44, 45); letter-spacing: 0px; background: rgb(255, 255, 255); font-size: 16px; font-family: 微软雅黑;">反通知程序</span></strong></span>
</p>
<p style="margin: 0px 0px 10px; text-indent: 28px; padding: 0px; vertical-align: baseline; text-align: left;">
    <span style="color: rgb(41, 44, 45); letter-spacing: 0px; background: rgb(255, 255, 255); font-size: 16px; font-family: 微软雅黑;">${gongsi}将投诉通知到作品提供者，若内容提供者认为作品不侵权的可提供相关证据反通知到${gongsi}。反通知书需作品提供者亲笔签名，若为单位则需加盖单位公章。</span>
</p>
<p style="margin: 0px 0px 10px; text-indent: 28px; padding: 0px; vertical-align: baseline; text-align: left;">
    <span style="font-size: 16px;"><strong><span style="color: rgb(41, 44, 45); letter-spacing: 0px; background: rgb(255, 255, 255); font-size: 16px; font-family: 微软雅黑;">邮寄地址</span></strong></span>
</p>
<p style="margin: 0px 0px 10px; text-indent: 28px; padding: 0px; vertical-align: baseline; text-align: left;">
    <span style="color: rgb(41, 44, 45); letter-spacing: 0px; background: rgb(255, 255, 255); font-size: 16px; font-family: 微软雅黑;">公司名称：${gongsi}</span>
</p>
<p style="margin: 0px 0px 10px; text-indent: 28px; padding: 0px; vertical-align: baseline; text-align: left;">
    <span style="color: rgb(41, 44, 45); letter-spacing: 0px; background: rgb(255, 255, 255); font-size: 16px; font-family: 微软雅黑;">联系地址：${dizhi}</span>
</p>
<p style="margin: 0px 0px 10px; text-indent: 28px; padding: 0px; vertical-align: baseline; text-align: left;">
    <span style="font-size: 16px;"><span style="color: rgb(41, 44, 45); letter-spacing: 0px; background: rgb(255, 255, 255); font-size: 16px; font-family: 微软雅黑;">联系电话：</span><span style="font-family: 宋体; font-size: 16px;">${dianhua}</span></span>
</p>
<p style="margin: 0px 0px 10px; text-indent: 28px; padding: 0px; vertical-align: baseline; text-align: left;">
    <span style="font-size: 16px;"><span style="color: rgb(41, 44, 45); letter-spacing: 0px; background: rgb(255, 255, 255); font-size: 16px; font-family: 微软雅黑;">固定电话：</span><span style="font-family: 宋体; font-size: 16px;">${dianhua}</span></span>
</p>
<p>
    <br/>
</p>`
            }
        },
       
    }
    </script>
    
    <style scoped>
    .cartoon{
        width: 1200px;
        padding-bottom: 50px;
        /* box-shadow: 0 3px 8px rgba(0,0,0,.3); */
        margin: 0 auto;
        padding: 50px 25px 100px;
        box-sizing: border-box;
        border-radius: 15px;
        background-color: #fff;
    }
    .title{
        background: url('../assets/image/border.png') no-repeat center center;
        background-size: 100% 100%;
        width: 300px;
        height: 109px;
        line-height: 109px;
        font-size: 32px;
        font-family: 700;
        margin: 0 auto;
    }
    
    </style>