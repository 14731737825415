<template>
  <div class="about" style="" >
    <div class="content">
    <div class="double1" >
      <div class="img">
        <img
          style="width: 80px; height: 80px"
          class="logo"
          src="@/assets/image/QQ.png"
          alt=""
        />
      </div>
      <div class="name">
        {{ qq }}
      </div>
    </div>
    <div class="double2">
      <div class="img">
        <img
          style="width: 80px; height: 80px"
          class="logo"
          src="@/assets/image/xinfen.png"
          alt=""
        />
      </div>
      <div class="name">
        {{ youxiang }}
      </div>
    </div>
    <div class="double1">
      <div class="img">
        <img
          style="width: 80px; height: 80px"
          class="logo"
          src="@/assets/image/phone.png"
          alt=""
        />
      </div>
      <div class="name">
      {{ dianhua }}
      </div>
    </div>
    <div class="double2">
      <div class="img">
        <img
          style="width: 80px; height: 80px"
          class="logo"
          src="@/assets/image/weizhi.png"
          alt=""
        />
      </div>
      <div class="name">
        {{ dizhi }}
      </div>
    </div>
    </div>
  </div>
</template>

 <script>
import { dianhua } from '../mixin';

export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped >
.about {
   display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
}
.content{
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  height: 100vh;
}
.double1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 400px;
  box-sizing: border-box;
  padding: 0 20px;
  margin-left: 25px;
  margin-bottom: 25px;
  height: 250px;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 3px 8px rgb(0 0 0 / 30%);
  animation: bounceInLeft 2s infinite cubic-bezier(0.215, 0.610, 0.355, 1.000);
  animation-iteration-count:1;
}
.double2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 400px;
  box-sizing: border-box;
  padding: 0 20px;
  margin-left: 25px;
  margin-bottom: 25px;
  height: 250px;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 3px 8px rgb(0 0 0 / 30%);
  animation: bounceInRight 2s infinite cubic-bezier(0.215, 0.610, 0.355, 1.000);
  animation-iteration-count:1;

}
.name {
  width: 360px;
  height: 40px;
  font-size: 22px;
  margin-top: 20px;
}
@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
}

60% {
    opacity: 1;
    transform: translateX(30px);
}
80% {
    transform: translateX(-10px);
}
100% {
    transform: translateX(0);
}
}
@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
}
60% {
    opacity: 1;
    transform: translateX(-30px);
}
80% {
    transform: translateX(10px);
}

100% {
    transform: translateX(0);
}
}
</style>
