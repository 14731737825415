<template>
  <div class="home">
    <el-carousel indicator-position="outside" height="40vh">
      <el-carousel-item v-for="item in list" :key="item">
        <img :src="item" alt="" style="width:100vw;height: 40vh;object-fit:cover">
      </el-carousel-item>
    </el-carousel>

    <div style="text-align: center; font-size: 30px; font-weight: bold; margin: 20px 0;">
      <span style="color:#F15717">资源</span>
      <span style="color:#000">中心</span>
    </div>
    <div class="bottom">
      


      <router-link to="/Cartoon">
        <div class="bottom-item">
        <img src="@/assets/image/index/pic1.png" alt="" >
        <div style="font-weight: bold;">好棒棒</div>
        <div>这里有好多精彩漫画推荐</div>
      </div>
    </router-link>
    <router-link to="/music">
   
      <div class="bottom-item">
        <img src="@/assets/image/index/pic2.png" alt="" >
        <div style="font-weight: bold;">真开心</div>
        <div>这里的音乐超好听</div>
      </div>
    </router-link>
    <router-link to="/expression">
        <div class="bottom-item">
        <img src="@/assets/image/index/pic3.png" alt="" >
        <div style="font-weight: bold;">好喜欢</div>
        <div>这里的表情宝都想要</div>
      </div>
      </router-link>
 
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  data(){
    return {
      list:[
        require("@/assets/image/index/index1.jpg"),
        // require("@/assets/image/index/index2.jpg"),
        require("@/assets/image/index/index3.jpg"),
        // require("@/assets/image/banner2.jpg"),
      ]
    }
  }
}
</script>
<style>
  .home{
    margin-top: 120px;
  }
  .bottom{
    margin:30px auto;
    width: 1200px;
    display: flex;
    justify-content: space-around;
  }
</style>
