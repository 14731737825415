import Vue from 'vue'
import App from './App.vue'
import router from './router'
import config from './common/config.js'
import VueAxios from 'axios';
import apiMixin from './api/mixin'

import axios from './common/axios';


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { mixins } from './mixin/index.js';
Vue.use(ElementUI);
// Vue.use(VueAxios,axios);

Vue.config.productionTip = false
Vue.prototype.$base = config
Vue.prototype.$axios = axios
Vue.prototype.$api = apiMixin

Vue.mixin(mixins)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
