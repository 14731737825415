/**
 * 全站http配置
 *
 * axios参数说明
 * isSerialize是否开启form表单提交
 * isToken是否需要token
 */
import Axios from 'axios'
import {getStorage,setStorage} from "@/common/utils.js"
import router from "@/router/index.js"



// import {
//   getToken
// } from '@/util/auth'
  
import {
  Message
} from 'element-ui'
import {
  baseUrl
} from './config';

let baseURL = "";
if(process.env.NODE_ENV === 'development'){
  baseURL = "/api";
}else{
  baseURL = baseUrl;
}

let axios = Axios.create({
  //baseURL: baseUrl
   baseURL
})

axios.defaults.timeout = 10000;
//返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};
//跨域请求，允许保存cookie
axios.defaults.withCredentials = true;

//HTTPrequest拦截
axios.interceptors.request.use(config => {
  //console.log(config,77)
  // const meta = (config.meta || {});
  if (getStorage("token")) {
    config.headers['token'] = getStorage("token") // 让每个请求携带token--['Authorization']为自定义key 请根据实际情况自行修改
  }
  if (config.method === 'post') {
    removeNull(config.data)
  } else {
    removeNull(config.params)
  }
  return config
}, error => {
  return Promise.reject(error)
});
//HTTPresponse拦截
axios.interceptors.response.use(res => {
  let message = res.data.msg
  //如果是401则跳转到登录页面
  if (res.status === 401) {
    setStorage("token",null)
    router.replace({ path: '/login' })
  }
  // 如果请求为非200否者默认统一处理
  if (res.data.status !== 1) {
    Message({
      message: message,
      type: 'error',
      offset:"100"
    })
    return Promise.reject(new Error(message))
  }
  return res.data;
}, error => {
  return Promise.reject(new Error(error));
})

// 去除null
let removeNull = function (option) {
  if (!option) {
    return;
  }
  for (var attr in option) {
    if (option[attr] === null) {
      delete option[attr];
      continue;
    }
    if (typeof (option[attr]) == "object") {
      removeNull(option[attr]);
    }
  }
}
export default axios;